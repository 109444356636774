import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { configure } from "./configure";

// configure the amplify client
configure(); 

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
if(window.location.hostname.includes("dev")) {
  reportWebVitals() // only report web vitals in dev
}