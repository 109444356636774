const schema = `"List of available investment structures - currently only used for filtering query results."
enum InvestmentProductStructure {
  INVESTMENT_PRODUCT_CLOSED_ENDED
  INVESTMENT_PRODUCT_OPEN_ENDED
}

type Query {
  products(investmentProductStructure: InvestmentProductStructure): [Product]
}

"""
The institutional strategy typically associated with GIPS composites maintained within the firm.
Note that product strategies are created seperately to the products themselves.
When creating a product - it may be linked to one or more of the product strategies that have been created.
Not to be confused with Investment Strategies where there is a predefined list of values that must be chosen from.  
"""
type ProductStrategy {
  "Unique GUID that is generated for each product strategy created in Daphne."
  id: ID!
  "The name of the product strategy."
  name: String!
  "The description of the product strategy."
  description: String!
  "List of products that this strategy has been associated with."
  products: [Product!]! 
  "List of other strategies that are considered to be parents to this strategy. This is relevant in cases where one strategy implements another."
  parents: [ProductStrategy!]!
  "List of other strategies that are considered to be children to this strategy. This is relevant in cases where one strategy implements another."
  children: [ProductStrategy!]!
  "Any additional information related to the product strategy can be captured as items in this list."
  metadata: [Metadata!]!
}

"This interface represents the data attributes that are common among all product types within Daphne."
interface Product {
  "Unique GUID that is generated for each product created in Daphne."
  id: ID!
  "The name of the product."
  name: String!
  "Product sentence elevator pitch. Indicative length: 1 sentence."
  tagline: String
  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String
  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String
  "The product support contact to answer any questions related to the product."
  supportContact: String
  "List of attachments that are associated with the product. Each attachment will have both a filename and a URL."
  attachments: [Attachment!]!
  "List of products that are considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parents: [ProductRelationship!]!
  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  children: [ProductRelationship!]!
  "List of products that are considered to be siblings of this product. An example of usage here would be relating different vintages of the same investment product together."
  siblings: [ProductRelationship!]!
  "Any additional information related to the product can be captured as items in this list."
  metadata: [Metadata!]!
}

"This type captures the detail regarding one product's relationship to another."
type ProductRelationship {
  "The unique GUID of the related product."
  productId: ID!
  "The type of relationship between the products."
  type: ProductRelationshipType!
  "Flag to determine whether the relationship is with another internal product or a 3rd party product."
  isExternal: Boolean!
}

"List of available relationship types that can be used when relating one product to another."
enum ProductRelationshipType {
  VINTAGE_FAMILY
  MASTER_FEEDER
  PARALLEL_FUND
  CO_INVESTMENT
  SECONDARY
  SIDE_CAR
}

"This interface represents the data attributes that are common among all investment product types within Daphne."
interface InvestmentProduct {
  "List of identifiers associated with the product. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The investment type of the product."
  investmentType: InvestmentType!
  "The asset class of this investment product."
  assetClass: AssetClass!
  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: String
  "The geographical focus of the investment product."
  geographicalFocus: [CountryISOCode!]!
  "One or many industries that the investment product targets."
  industry: [Industry!]!
  "List of product strategies that this investment product implements. Note that these strategies are reflective of GIPS composites that are set up at the firm and may not be relavant for all customers."
  productStrategies: [ProductStrategy!]!
  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!
  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!
  "Total amount of investment provided by the general partner."
  generalPartnerCommitmentAmount: Price
  "Percentage of the total amount of investment provided by the general partner."
  generalPartnerCommitmentPercentage: Float
  "The waterfacll method that is utilised within the investment product."
  waterfall: Waterfall
  "The gross and net targets for the 'internal rate of return' of the investment product."
  targetInternalRateOfReturn: GrossNetRange
  "The gross and net targets for the 'multiple on invested capital' of the investment product."
  targetMultipleOnInvestedCapital: GrossNetRange
  "The leverage range that the investment product is targeting."
  targetLeverage: FloatRange
  "The gross and net targets for the 'return on equity' of the investment product."
  targetReturnOnEquity: GrossNetRange
  "Any additional information or description relating to the target returns."
  targetReturnDescription: String
  "The expected number of investments that the product will make."
  expectedNumberOfInvestments: IntegerRange
  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity!
  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  relatedLegalEntities: [AssociateLegalEntity!]!
}

"This type represents the data attributes that are relevant to open-ended investment structures."
type InvestmentProductOpenEnded implements Product & InvestmentProduct {
  "Unique GUID that is generated for each product created in Daphne."
  id: ID!
  "The name of the product."
  name: String!
  "Product sentence elevator pitch. Indicative length: 1 sentence."
  tagline: String
  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String
  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String
  "The product support contact to answer any questions related to the product."
  supportContact: String
  "List of attachments that are associated with the product. Each attachment will have both a filename and a URL."
  attachments: [Attachment!]!
  "List of products that are considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parents: [ProductRelationship!]!
  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  children: [ProductRelationship!]!
  "List of products that are considered to be siblings of this product. An example of usage here would be relating different vintages of the same investment product together."
  siblings: [ProductRelationship!]!
  "Any additional information related to the product can be captured as items in this list."
  metadata: [Metadata!]!
  "List of identifiers associated with the product. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The investment type of the product."
  investmentType: InvestmentType!
  "The asset class of this investment product."
  assetClass: AssetClass!
  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: String
  "The geographical focus of the investment product."
  geographicalFocus: [CountryISOCode!]!
  "One or many industries that the investment product targets."
  industry: [Industry!]!
  "List of product strategies that this investment product implements. Note that these strategies are reflective of GIPS composites that are set up at the firm and may not be relavant for all customers."
  productStrategies: [ProductStrategy!]!
  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!
  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!
  "Total amount of investment provided by the general partner."
  generalPartnerCommitmentAmount: Price
  "Percentage of the total amount of investment provided by the general partner."
  generalPartnerCommitmentPercentage: Float
  "The waterfacll method that is utilised within the investment product."
  waterfall: Waterfall
  "The gross and net targets for the 'internal rate of return' of the investment product."
  targetInternalRateOfReturn: GrossNetRange
  "The gross and net targets for the 'multiple on invested capital' of the investment product."
  targetMultipleOnInvestedCapital: GrossNetRange
  "The leverage range that the investment product is targeting."
  targetLeverage: FloatRange
  "The gross and net targets for the 'return on equity' of the investment product."
  targetReturnOnEquity: GrossNetRange
  "Any additional information or description relating to the target returns."
  targetReturnDescription: String
  "The expected number of investments that the product will make."
  expectedNumberOfInvestments: IntegerRange
  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity!
  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  relatedLegalEntities: [AssociateLegalEntity!]!
  "The funding status of the product - is it open for subscription?"
  fundingStatus: FundingStatus!
  "The assets under management limit in the product that may potentially trigger a soft close."
  maximumOffering: Price
  "List of share classes within the investment product. All products must have a default share class within Daphne."
  shareClasses: [ShareClass!]!
}

"This type represents the data attributes that are relevant to closed-ended investment structures."
type InvestmentProductClosedEnded implements Product & InvestmentProduct {
  "Unique GUID that is generated for each product created in Daphne."
  id: ID!
  "The name of the product."
  name: String!
  "Product sentence elevator pitch. Indicative length: 1 sentence."
  tagline: String
  "Product description, enabling an Asset Manager to detail all of the relevant marketing details for the product."
  description: String
  "Captures general product disclaimers e.g. disclaimers linked to any statements made in the description."
  generalDisclaimer: String
  "The product support contact to answer any questions related to the product."
  supportContact: String
  "List of attachments that are associated with the product. Each attachment will have both a filename and a URL."
  attachments: [Attachment!]!
  "List of products that are considered to be a parent of this product. Used mainly for representing internal and external Master/Feeder relationships."
  parents: [ProductRelationship!]!
  "List of products that are considered to be children of this product. Used mainly for representing internal and external Master/Feeder relationships."
  children: [ProductRelationship!]!
  "List of products that are considered to be siblings of this product. An example of usage here would be relating different vintages of the same investment product together."
  siblings: [ProductRelationship!]!
  "Any additional information related to the product can be captured as items in this list."
  metadata: [Metadata!]!
  "List of identifiers associated with the product. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The investment type of the product."
  investmentType: InvestmentType!
  "The asset class of this investment product."
  assetClass: AssetClass!
  "The inception date of the investment product that reflects when it was created and available for marketing."
  inceptionDate: String
  "The geographical focus of the investment product."
  geographicalFocus: [CountryISOCode!]!
  "One or many industries that the investment product targets."
  industry: [Industry!]!
  "List of product strategies that this investment product implements. Note that these strategies are reflective of GIPS composites that are set up at the firm and may not be relavant for all customers."
  productStrategies: [ProductStrategy!]!
  "List of the investment strategies associated with this product."
  investmentStrategies: [InvestmentStrategy!]!
  "List of the individuals that make up the fund manager team and are responsible for managing the investment strategy."
  fundManagerTeam: [Person!]!
  "Total amount of investment provided by the general partner."
  generalPartnerCommitmentAmount: Price
  "Percentage of the total amount of investment provided by the general partner."
  generalPartnerCommitmentPercentage: Float
  "The waterfacll method that is utilised within the investment product."
  waterfall: Waterfall
  "The gross and net targets for the 'internal rate of return' of the investment product."
  targetInternalRateOfReturn: GrossNetRange
  "The gross and net targets for the 'multiple on invested capital' of the investment product."
  targetMultipleOnInvestedCapital: GrossNetRange
  "The leverage range that the investment product is targeting."
  targetLeverage: FloatRange
  "The gross and net targets for the 'return on equity' of the investment product."
  targetReturnOnEquity: GrossNetRange
  "Any additional information or description relating to the target returns."
  targetReturnDescription: String
  "The expected number of investments that the product will make."
  expectedNumberOfInvestments: IntegerRange
  "Detail relating to the legal entity that owns this investment product."
  legalEntity: InvestmentProductLegalEntity!
  "Detail relating to other legal entities that are involved in the management and maintainence of this product."
  relatedLegalEntities: [AssociateLegalEntity!]!
  "The stage of closed-ended lifecycle that the investment product is in."
  stage: Stage!
  "The vintage year of the product captured as YYYY."
  vintageYear: Int
  "The estimated close date of the product."
  estimatedCloseDate: String
  "The full term of the investment product"
  productTermYears: Int!
  "The potential extension of the investment product's term subject to approval e.g. 1 + 1"
  termExtension: String
  "The maximum amount the investment product is allowed to raise"
  hardcap: Price
  "Target raise amount of the investment product"
  targetRaise: Price!
  "The current raise amount of the investment product"
  currentRaise: Price
  "The closing schedule of the investment product"
  closingSchedule: [ClosingSchedule!]!
  "List of share classes within the investment product. All products must have a default share class within Daphne."
  shareClasses: [ShareClass!]!
}

"List of available investment types that can be associated with an investment product."
enum InvestmentType {
  FUND
  SECONDARY_FUND
  CO_INVESTMENT
  DIRECT_DEAL
  FUND_OF_FUNDS
  FUND_OF_SECONDARIES
  FUND_OF_CO_INVESTMENTS
  INSURANCE_DEDICATED_FUND
}

"List of available asset classes that can be associated with an investment product."
enum AssetClass {
  PRIVATE_EQUITY
  PRIVATE_CREDIT
  REAL_ESTATE
  INFRASTRUCTURE
  HYBRID
}

"""
List of available asset classes that can be associated with an investment product.
Note that these values should all be mappable to an equivalent location in widely used standards like GICS and MSCI.
"""
enum Industry {
  CHEMICALS
  COMMERCIAL_SERVICES
  CONSTRUCTION
  CONSUMER_DURABLES
  CYBER
  EDUCATION
  ENERGY
  ESG
  FINANCIAL_SERVICES
  FINTECH
  FOOD_PRODUCTION
  FOOD_RETAIL
  FORESTRY
  GENERAL_TECHNOLOGY
  HEALTHCARE
  HOSPITALITY
  INDUSTRIAL_GOODS_AND_SERVICES
  INDUSTRY_AGNOSTIC
  INSURANCE
  MANUFACTURING
  MEDIA
  METALS_AND_MINERALS_PRODUCTION
  METALS_AND_MINING
  PHARMACEUTICALS
  PROFESSIONAL_SERVICES
  PUBLIC_SERVICES
  REAL_ESTATE
  RETAIL
  SUSTAINABILITY
  TELECOMMUNICATIONS
  TRANSPORT
  UTILITIES
}

"List of available investment strategies"
enum InvestmentStrategy {
  BUYOUTS
  DISTRESSED_FOR_CONTROL
  SPECIAL_SITUATIONS
  GENERAL_PARTNER_STAKES
  GROWTH_EQUITY
  VENTURE_CAPITAL
  EARLY_STAGE_VENTURE_CAPITAL
  MID_STAGE_VENTURE_CAPITAL
  LATE_STAGE_VENTURE_CAPITAL
  MULTI_STAGE_VENTURE_CAPITAL
  SECONDARY_INVESTMENTS
  SINGLE_GENERAL_PARTNER
  DIRECT_LENDING
  SENIOR_SECURED
  MEZZANINE
  DISTRESSED_CREDIT
  SPECIALITY_FINANCE
  VENTURE_DEBT
  ASSET_BACKED_LENDING
  INFRASTRUCTURE_CREDIT
  SUBORDINATED
  STRUCTURED_CREDIT
  STRUCTURED_EQUITY
  MULTI_STRATEGY
  RESIDENTIAL_PROPERTY
  OFFICE_PROPERTY
  HOSPITALITY_PROPERTY
  LIFE_SCIENCES_PROPERTY
  INDUSTRIAL_PROPERTY
  PROPERTY_DEBT_AND_LOANS
  RETAIL_PROPERTY
  OTHER_PROPERTY
  POWER_INFRASTRUCTURE
  RENEWABLES_INFRASTRUCTURE
  COMMUNICATIONS_INFRASTRUCTURE
  TRANSPORTATION_INFRASTRUCTURE
  SOCIAL_INFRASTRUCTURE
  MIDSTREAM_INFRASTRUCTURE
  LOWER_MARKET
  MIDDLE_MARKET
  UPPER_MARKET
  CORE
  CORE_PLUS
  VALUE_ADDED
  OPPORTUNISTIC
  DISTRESSED 
  DIVERSIFIED
}

"The closing schedule for a closed-ended fund."
type ClosingSchedule {
  "The date that the round opened."
  openingDate: String
  "The date that the round closed."
  closingDate: String!
  "The amount that the round closed at."
  closingAmount: Price!
  "The description of the round (i.e. first close, second close)."
  closingDescription: String
  "Flag to determine if this is the final close date and amount."
  isFinalClose: Boolean!
}

"""
Under a European waterfall structure carried interest is calculated at the fund-level across all deals. 
By contrast, an American waterfall is calculated on a deal-by-deal basis, where GPs are compensated for each successful deal.
"""
type Waterfall {
  "The type of waterfall methodology in use (American or European)."
  type: WaterfallType!
  "Is the waterfall methodology a standard American or European waterfall or has it been modified?"
  isModified: Boolean!
  "If the waterfall has been modified - this field should contain detail on the modifications."
  additionalInformation: String
}

"List of available waterfall types."
enum WaterfallType {
  AMERICAN
  EUROPEAN
}

"List of available funding statuses for open-ended investment products."
enum FundingStatus {
  OPEN
  CLOSED
  CLOSED_TO_NEW_SUBSCRIPTIONS
}

"List of available stages within the lifecycle of a closed-ended fund."
enum Stage {
  FUNDRAISING
  INVESTING
  HARVESTING
  FULLY_REALIZED
  WOUND_DOWN
}

"This interface represents the data attributes that are common among all share class types within Daphne."
interface ShareClass {
  "List of identifiers associated with the share class. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The name of the share class."
  name: String!
  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean!
  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!
  "The minimum amount allowed to be invested in the class."
  minimumInvestment: Price!
  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float
  "Does the fund have a high water mark?"
  hasHighWatermark: Boolean
  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float
  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float
  "Details relating to how the class distributes."
  distributionSchedule: Schedule
  "The frequency at which the class is priced."
  valuationFrequency: Frequency
  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!
  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!
  "Any additional information relating to the class."
  additionalInformation: String
  "List of reports associated with the class. These reports contain time series information relating to performance and cash flow."
  report: [Report!]!
  "List of valuations of the class at different points in time. Includes both total net assets and net asset value per unit share."
  netAssetValue: [NetAssetValue!]!
}

"This type represents the data attributes that are relevant to open-ended share classes."
type ShareClassOpenEnded implements ShareClass {
  "List of identifiers associated with the share class. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The name of the share class."
  name: String!
  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean!
  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!
  "The minimum amount allowed to be invested in the class."
  minimumInvestment: Price!
  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float
  "Does the fund have a high water mark?"
  hasHighWatermark: Boolean
  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float
  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float
  "Details relating to how the class distributes."
  distributionSchedule: Schedule
  "The frequency at which the class is priced."
  valuationFrequency: Frequency
  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!
  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!
  "Any additional information relating to the class."
  additionalInformation: String
  "List of reports associated with the class. These reports contain time series information relating to performance and cash flow."
  report: [Report!]!
  "List of valuations of the class at different points in time. Includes both total net assets and net asset value per unit share."
  netAssetValue: [NetAssetValue!]!
  "Details relating to the lock up period of the class."
  lockUp: LockUp
  "Details relating to any redemption gates that may exist for the class."
  redemptionGates: [RedemptionGate!]!
  "The redemption schedule for the class."
  redemptionSchedule: RegularSchedule
  "The subscription schedule for the class."
  subscriptionSchedule: RegularSchedule
}

"This type represents the data attributes that are relevant to closed-ended share classes."
type ShareClassClosedEnded implements ShareClass {
  "List of identifiers associated with the share class. These can be custom internal identifiers or external codes like ISIN, TICKER and CUSIP."
  identifiers: [SecurityIdentifier!]!
  "The name of the share class."
  name: String!
  "Flag that determines whether or not the share class is considered to be the representative class for the investment product. Each product must have one representative share class."
  isRepresentativeClass: Boolean!
  "The investor types who can invest in this specific class."
  investorTypes: [InvestorTypes!]!
  "The minimum amount allowed to be invested in the class."
  minimumInvestment: Price!
  "Investors continue to receive 100% of classes proceeds until the it has achieved its hurdle rate."
  hurdleRate: Float
  "Does the fund have a high water mark?"
  hasHighWatermark: Boolean
  "Amount that the general partner will receive of their original investment once hurdle rate achieved."
  catchUp: Float
  "Carried interest determines the percentage of profit made on the returns of a class that goes to the asset manager."
  carriedInterestRate: Float
  "Details relating to how the class distributes."
  distributionSchedule: Schedule
  "The frequency at which the class is priced."
  valuationFrequency: Frequency
  "List of management fees that are associated with this class."
  managementFees: [ManagementFee!]!
  "List of any additional fees that apply to the class."
  additionalFees: [AdditionalFee!]!
  "Any additional information relating to the class."
  additionalInformation: String
  "List of reports associated with the class. These reports contain time series information relating to performance and cash flow."
  report: [Report!]!
  "List of valuations of the class at different points in time. Includes both total net assets and net asset value per unit share."
  netAssetValue: [NetAssetValue!]!
}

"Definition of a lock up period"
type LockUp {
  "The type of lock up that is being applied."
  lockUpType: LockUpType!
  "The duration of the lock up period measured in years. e.g. 6 months represented as 0.5yrs"
  durationYears: Float!
  "Any addtional detail relating to the lock up period."
  description: String
}

"List of available lock up types."
enum LockUpType {
  SOFT_LOCK
  HARD_LOCK
}

"Definition of a redemption gate."
type RedemptionGate {
  "The type of the redemption gate."
  gateType: GateType!
  "The percentage of a redemption above which the gate will apply." 
  gatePercentage: Float!
  "The frequency at which the redemption gate applies."
  gateFrequency: Frequency!
  "The frequency type associated with the redemption gate."
  gateFrequencyType: GateFrequencyType!
  "Any additional information associated with redemption gate."
  gateAdditionalInformation: String
}

"List of available redemption gate types."
enum GateType {
  FUND_LEVEL
  CLASS_LEVEL
  INVESTOR_LEVEL
}

"List of available redemption gate frequency types."
enum GateFrequencyType {
  FISCAL
  ROLLING
}

"List of available investor types."
enum InvestorTypes {
  QUALIFIED_PURCHASER
  ACCREDITED_INVESTOR
  INSTITUTIONAL_INVESTOR
  KNOWLEDGEABLE_EMPLOYEE
  INSTITUTIONAL
}

"Definition of report type for the capture of performance and cash flow data."
type Report {
  "The metric that is being reported on."
  field: ReportField!
  "The period over which the measurement has been captured."
  period: ReportPeriod!
  "The date that this measurement applies to."
  endDate: String!
  "The currency basis of the measurement."
  currencyBasis: CurrencyISOCode
  "The value for the measurement gross of fees."
  gross: Float
  "The value for the measurement net of fees."
  net: Float
  "Any additional information relating to this report."
  additionalInformation: String
}

"List of available metrics that a report can be created on."
enum ReportField {
  INTERNAL_RATE_OF_RETURN
  RETURN_ON_EQUITY
  MULTIPLE_ON_INVESTED_CAPITAL
  DISTRIBUTED_TO_PAID_IN_CAPITAL
  RESIDUAL_VALUE_TO_PAID_IN_CAPITAL
  INCOME_RETURN
  APPRECIATION_RETURN
  TOTAL_RETURN
  ANNUALIZED_RETURN
  CONTRIBUTIONS
  DISTRIBUTIONS
}

"List of available periods that a report measurement can be made over."
enum ReportPeriod {
  SINCE_INCEPTION
  ONE_DAY
  ONE_WEEK
  TWO_WEEKS
  ONE_MONTH
  TWO_MONTHS
  ONE_QUARTER
  SIX_MONTHS
  NINE_MONTHS
  ONE_YEAR
  EIGHTEEN_MONTHS
  TWO_YEARS
  THREE_YEARS
  FIVE_YEARS
  TEN_YEARS
}

"Type for capturing the valuation data for an investment product."
type NetAssetValue {
  "The date at which the valuation data applies."
  endDate: String!
  "The number of units currently in issue."
  unitsInIssue: Int
  "The currency basis of the valuation measurement."
  currencyBasis: CurrencyISOCode!
  "The total net asset value gross of liabilities."
  grossTotalNetAssets: Float
  "The total net asset value net of liabilities."
  netTotalNetAssets: Float
  "The net asset value per unit share gross of liabilities."
  grossNavPerUnitShare: Float
  "The net asset value per unit share net of liabilities."
  netNavPerUnitShare: Float
  "Flag that signifies whether the valuation is an estimate or an actual measurement."
  isEstimate: Boolean!
}


"This interface represents the data attributes that are common among all fee types within Daphne."
interface Fee {
  "The structure of the fee."
  structure: FeeStructure!
  "The frequency at which the fee is to be paid."
  paymentFrequency: PaymentFrequency!
  "The rate of the fee."
  rate: Float!
  "Any additional information associated with the fee."
  additionalInformation: String
}

"This type represents the attributes required to define a management fee."
type ManagementFee implements Fee {
  "The structure of the fee."
  structure: FeeStructure!
  "The frequency at which the fee is to be paid."
  paymentFrequency: PaymentFrequency!
  "The rate of the fee."
  rate: Float!
  "Any additional information associated with the fee."
  additionalInformation: String
  "Is this the main fee that is used for marketing purposes?"
  isHeadlineFee: Boolean!
}

"This type represents the attributes required to define other additional fees."
type AdditionalFee implements Fee {
  "The name of the fee being captured."
  name: String!
  "The structure of the fee."
  structure: FeeStructure!
  "The frequency at which the fee is to be paid."
  paymentFrequency: PaymentFrequency!
  "The rate of the fee."
  rate: Float!
  "Any additional information associated with the fee."
  additionalInformation: String
}

"List of available fee structures."
enum FeeStructure {
  SINGLE_RATE
  TIERED_RATE
}

"List of available fee payment frequencies."
enum PaymentFrequency {
  MONTHLY_IN_ADVANCE
  MONTHLY_IN_ARREARS
  QUARTERLY_IN_ADVANCE
  QUARTERLY_IN_ARREARS
  SEMI_ANNUALLY_IN_ADVANCE
  SEMI_ANNUALLY_IN_ARREARS
  ANNUALLY_IN_ADVANCE
  ANNUALLY_IN_ARREARS
}

"This interface represents the data attributes that are common among all legal entity types within Daphne."
interface LegalEntity {
  "The name of the legal entity."
  legalName: String!
  "The legal entity identifier of the entity."
  legalEntityIdentifier: String
  "The structure of the legal entity."
  legalStructure: LegalStructure!
  "The country in which the legal entity is based."
  jurisdictionCountry: CountryISOCode!
  "The US state in which the legal entity is based."
  jurisdictionState: StateISOCode
  "A description of the legal entity."
  description: String
}

"Legal entity details that are associated with an investment product."
type InvestmentProductLegalEntity implements LegalEntity {
  "The name of the legal entity."
  legalName: String!
  "The legal entity identifier of the entity."
  legalEntityIdentifier: String
  "The structure of the legal entity."
  legalStructure: LegalStructure!
  "The country in which the legal entity is based."
  jurisdictionCountry: CountryISOCode!
  "The US state in which the legal entity is based."
  jurisdictionState: StateISOCode
  "A description of the legal entity."
  description: String
  "Tax eligibility of investors able to invest in the fund"
  investorTaxEligibility: InvestorTaxEligibility
  "Type of tax reporting required for the fund"
  taxReportingDocumentation: [TaxReportingDocumentation!]!
}

"Legal entity details that are associated with companies involved in the management of the fund."
type AssociateLegalEntity implements LegalEntity {
  "The name of the legal entity."
  legalName: String!
  "The legal entity identifier of the entity."
  legalEntityIdentifier: String
  "The structure of the legal entity."
  legalStructure: LegalStructure!
  "The country in which the legal entity is based."
  jurisdictionCountry: CountryISOCode!
  "The US state in which the legal entity is based."
  jurisdictionState: StateISOCode
  "A description of the legal entity."
  description: String
  "The role that legal entity carries out."
  role: LegalRole!
}

"List of available legal roles for defined legal entities."
enum LegalRole {
  GENERAL_PARTNER
  FUND_MANAGEMENT_COMPANY
  INVESTMENT_ADVISER
  SUB_ADVISER
  FUND_ADMINISTRATOR
  FUND_ACCOUNTANT
  TRANSFER_AGENT
  CUSTODIAN
  DEPOSITORY
  THIRD_PARTY_MANCO_SERVICE_PROVIDER
}

"List of available legal structures for defined legal entities."
enum LegalStructure {
  BUSINESS_DEVELOPMENT_COMPANY
  BUSINESS_TRUST
  GENERAL_PARTNERSHIP
  INTERVAL
  INVESTMENT_TRUST
  LIMITED_LIABILITY_COMPANY
  LIMITED_PARTNERSHIP
  NON_TRADED_REAL_ESTATE_INVESTMENT_TRUST
  REAL_ESTATE_INVESTMENT_TRUST
  SPECIAL_LIMITED_PARTNERSHIP
  UNIT_TRUST
  COMPANY
  OTHER
}

"List of available options for tax eligibility of investors."
enum InvestorTaxEligibility {
  US_TAXABLE
  US_TAX_EXEMPT
  NON_US
}

"List of tax documentation that may be required for the product."
enum TaxReportingDocumentation {
  TEN99
  K1
  NA
}

"This interface represents all the common attributes that are shared between schedule type within Daphne."
interface Schedule {
  "The frequency at which the schedule applies."
  frequency: Frequency!
  "Any additional information relating to the schedule."
  additionalInformation: String
}

"This type defines a schedule that occurs at regular intervals for set periods of time."
type RegularSchedule implements Schedule {
  "The frequency at which the schedule applies."
  frequency: Frequency!
  "Any additional information relating to the schedule."
  additionalInformation: String
  "The offset between the start of the period and the start of the schedule being defined."
  offset: Days!
  "The length of the period being described."
  window: Days!
}

"This type defines a schedule that highlights specific calendar dates."
type CalendarSchedule implements Schedule {
  "The frequency at which the schedule applies."
  frequency: Frequency!
  "Any additional information relating to the schedule."
  additionalInformation: String
  dates: [String!]!
}

"Type that is used for defining a number of days in a period."
type Days {
  "The type of day that this value related to."
  dayType: DayType!
  "The number of days."
  value: Int!
}

"List of available day types."
enum DayType {
  BUSINESS_DAY
  CALENDAR_DAY
}

"Type used for defining a persons name and role."
type Person {
  "The name of the person."
  name: String!
  "The role that the individual performs."
  role: String!
}

"Type used for capturing a value and it's associated currency."
type Price {
  "The amount to be captured."
  amount: Float!
  "The currency associated with the amount being captured."
  currency: CurrencyISOCode!
}

"List of available frequency types."
enum Frequency {
  DAILY
  WEEKLY
  FORTNIGHTLY
  SEMI_MONTHLY
  MONTHLY
  QUARTERLY
  SEMI_ANNUALY
  ANNUALY
  AD_HOC
}

"Type for capturing any addition metadata that can be attached to products or strategies."
type Metadata {
  "The name of the metadata field being captured."
  key: String!
  "The value of the metadata field being captured."
  value: String!
  "The type of data being captured within this metadata field."
  type: MetadataType!
}

"List of available metadata types."
enum MetadataType {
  INT
  FLOAT
  STRING
  BOOLEAN
  DATE
  DATETIME
}

"Type for capturing a net and gross range banded by floats."
type GrossNetRange {
  "The minimum net value of the range."
  netMinimum: Float
  "The maximum net value of the range."
  netMaximum: Float
  "The minimum gross value of the range."
  grossMinimum: Float
  "The maximum gross value of the range."
  grossMaximum: Float
}

"Type for capturing a range banded by floats."
type FloatRange {
  "The minimum value of the range."
  minimum: Float
  "The maximium value of the range."
  maximum: Float
}

"Type for capturing a range banded by integers."
type IntegerRange {
  "The minimum value of the range."
  minimum: Int
  "The maximium value of the range."
  maximum: Int
}

"Type for capturing the name and location of an attachment."
type Attachment {
  "The name of the attachment."
  filename: String!
  "The location of the attachment."
  url: String!
}

"Type for capturing a security identifier and it's type."
type SecurityIdentifier {
  "The value of the identifier."
  identifier: String!
  "The type of the identifier."
  type: SecurityIdentifierType!
}

"List of available security identifier types."
enum SecurityIdentifierType {
  ISIN
  CUSIP
  SEDOL
  TICKER
  CUSTOM
}

"""
List of available currency codes - currently contains G20 plus Switzerland, Luxembourg and Cayman Islands.
Should be extended to all ISO country codes.
"""
enum CurrencyISOCode {
  ARS
  AUD
  BRL
  CAD
  CHF
  CNY
  EUR
  GBP
  INR
  IDR
  JPY
  KRW
  MXN
  RUB
  SAR
  TRY
  USD
  ZAR
}

"""
List of available country codes - currently contains G20 plus Switzerland, Luxembourg and Cayman Islands.
NOTE:
Should be extended to all ISO country codes.
Potentially we could also include supra-national codes.
"""
enum CountryISOCode {
  AR
  AU
  BR
  CA
  CH
  CN
  CY
  LU
  FR
  DE
  IT
  GB
  IN
  ID
  JP
  KR
  MX
  RU
  SA
  TR
  US
  ZA
}

"List of all available ISO State codes."
enum StateISOCode {
  AL
  AK
  AZ
  AR
  CA
  CO
  CT
  DE
  FL
  GA
  HI
  ID
  IL
  IN
  IA
  KS
  KY
  LA
  ME
  MD
  MA
  MI
  MN
  MS
  MO
  MT
  NE
  NV
  NH
  NJ
  NM
  NY
  NC
  ND
  OH
  OK
  OR
  PA
  RI
  SC
  SD
  TN
  TX
  UT
  VT
  VA
  WA
  WV
  WI
  WY
}

schema {
  query: Query
}`;

export default schema;