import Modal from 'react-modal';
import styles from '../styles.module.css';
import { useState } from 'react';

export interface ResetEnvironmentProps {
    isOpen: boolean;
    onReset: () => void;
    onCancel: () => void;
}

const ResetEnvironmentModal: React.FC<ResetEnvironmentProps> = (props) => {

    const { isOpen, onReset, onCancel } = props;
    const [isCodeValid, setIsCodeValid] = useState<boolean>(false);

    const verificationCode = Math.random().toString(36).substring(2, 7);

    const onCodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const code = event.target.value;
        setIsCodeValid(code === verificationCode);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onReset}
            shouldCloseOnOverlayClick={true}
            className={styles.modal}
            overlayClassName={styles.overlay}
            ariaHideApp={false}
        >
            <h2>Reset Environment</h2>
            <p>
                You are about to reset the environment. Please note that this environment contains mock data and the data model may change. As a user, you acknowledge that the environment may be updated over time. Resetting the environment will restore the initial mock data you started with.
                <br /><br />
                To proceed, please enter the code provided below to confirm your understanding.
            </p>
            <div className="verification-code">
                <strong>{verificationCode}</strong>
            </div>
            <input className="code-input" type="text" placeholder="Enter the 4-character code" onInput={onCodeInputChange}/>
            <button className={styles.resetButton} onClick={onReset} disabled={!isCodeValid}>
                Reset
            </button>
            <button className={styles.resetButton} onClick={onCancel}>
                Cancel
            </button>
        </Modal>
    )
};

export default ResetEnvironmentModal;